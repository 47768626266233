import React from 'react'
import Seo from '../../components/Seo'
import { useLangContext } from '../../context/lang.context'
import IntroSection from './IntroSection/IntroSection'
import { seo } from './seo'
import DangerousAreaContent from './DangerousAreaContent/DangerousAreaContent'

const Driver = () => {
  const { lang } = useLangContext()

  return (
    <>
      <Seo
        title={`Lista parkingów objęta zakazem postoju`}
        description={``}
        lang={lang}
      />
      <IntroSection />
      <DangerousAreaContent />
    </>
  )
}

export default Driver
