import React from 'react'
import { Link } from 'gatsby'
import { useLangContext } from '../../../context/lang.context'
import { sContent } from '../../../style/style'
import { colors } from '../../../style'

import { DangerousAreaList as list } from '../../DangerousArea/content'

const DangerousAreaContent = () => {
  const { lang } = useLangContext()

  return (
    <div css={[sContent, sContent2]}>
      <p>
        <strong>Niedotrzymanie warunków postoju będzie skutkowało nałożeniem kary za
        każdy przypadek. Ponadto każdy postój na parkingu oznaczonym jako
        niebezpieczny na wskazanej i załączonej do zlecenia liście, będzie
        traktowane jako rażące niedbalstwo, w szczególności w przypadku
        kradzieży towaru.</strong>
      </p>
      {list.map(({ country, parkings }) => (
        <div>
          <h3>{country}</h3>
          <ul>
            {parkings.map(({ city, coods, place }) => (
              <li>
                {country} | {city} | {coods} | {place}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  )
}

const sContent2 = {
  ul: {
    margin: '1rem 1rem 3rem 0',
    listStyleType: 'none',
    padding: 0,
    li: {
      color: 'rgba(255, 255, 255, .6)',
      lineHeight: '2em',
      fontSize: '1.125rem',
      margin: 0,
      marginBottom: '1rem',
    },
  },
  ol: {
    margin: '1rem 1rem 3rem',
    li: {
      color: 'rgba(255, 255, 255, .6)',
      lineHeight: '2em',
      fontSize: '1.125rem',
    },
    ul: {
      margin: 0,
    },
  },
  a: { color: colors.main },
}

export default DangerousAreaContent
