export const DangerousAreaIntro = {
  title: {
    pl: 'Lista parkingów objęta zakazem postoju',
  },
  back: {
    pl: 'CARGONITE',
  },
  desc: {
    pl: '',
  },
}

export const DangerousAreaList = [
  {
    country: 'Belgium',
    parkings: [
      {
        "city": "Nazareth",
        "coods": "50.97311, 3.6134539",
        "place": "Parking lot, 9810 Nazareth, Belgium"
      },
      {
        "city": "Houyet",
        "coods": "50.1429603, 5.07785755",
        "place": "Petit Vanlin, 5564 Houyet, Belgium"
      },
      {
        "city": "Hoogstraten",
        "coods": "51.419083, 4.7052903",
        "place": "2322 Hoogstraten, Belgium"
      },
      {
        "city": "Grâce-Hollogne",
        "coods": "50.651704, 5.478418",
        "place": "Belgium: Parking Area: VP Park, Grâce-Hollogne"
      },
      {
        "city": "Menen",
        "coods": "50.766327, 3.1729138",
        "place": "Parking Area: 8930 Menen, Belgium"
      },
      {
        "city": "Mol",
        "coods": "51.313896, 5.1923904",
        "place": "Parking Area: 2400 Mol, Belgium"
      },
    ],
  },
  {
    country: 'Czech Republic',
    parkings: [
      {
        "city": "Tvarožná",
        "coods": "49.18665, 16.758951",
        "place": "664 05 Tvarožná, Czech Republic"
      },
    ],
  },
  {
    country: 'France',
    parkings: [
      {
        "city": "Bedenac",
        "coods": "45.1737783, -0.3356489",
        "place": "Bedenac 17210 , France, Station Avia"
      },
      {
        "city": "Gidy",
        "coods": "47.98008, 1.8574519",
        "place": "Parking Area:45520 Gidy, France"
      },
      {
        "city": "Bligny-lès-Beaune",
        "coods": "46.969498, 4.835932",
        "place": "Parking Area: 21200 Bligny-lès-Beaune, France"
      },
    ],
  },
  {
    country: 'Germany',
    parkings: [
      {
        "city": "Mellingen",
        "coods": "50.935583, 11.387778",
        "place": "Aralallee 2, 99441 Mellingen, Germany"
      },
      {
        "city": "Eutingen im Gäu",
        "coods": "48.455861, 8.774333",
        "place": "Eutingen im Gäu, A81, Germany"
      },
      {
        "city": "Stadelhofen",
        "coods": "49.993972, 11.167528",
        "place": "96187 Stadelhofen, Germany"
      },
      {
        "city": "Roßwein",
        "coods": "51.090250, 13.233472",
        "place": "Rastplatz Hansens Holz Süd 04741 Roßwein, Germany"
      },
      {
        "city": "Schönwald",
        "coods": "51.994194, 13.724694",
        "place": "Parking on Bugkgraben 15910 Schönwald, Germany"
      },
      {
        "city": "Königslutter",
        "coods": "52.311250, 10.824222",
        "place": "Am Mühlenhop 1, 38154 Königslutter, Germany"
      },
      {
        "city": "Teutschenthal",
        "coods": "51.401778, 11.719639",
        "place": "Querfurter Platte North 06179 Teutschenthal, Germany"
      },
      {
        "city": "Hainichen",
        "coods": "50.961147, 13.072374",
        "place": "09661 Hainichen, Germany"
      },
      {
        "city": "Hainichen",
        "coods": "50.961147, 13.072374",
        "place": "09661 Hainichen, Niemcy"
      },
      {
        "city": "Lichtenau",
        "coods": "50.89338, 12.941336",
        "place": "Auerswalde, 09244 Lichtenau, Niemcy"
      },
      {
        "city": "Lipperland",
        "coods": "52.071232600917, 8.66432414717492",
        "place": "Lipperland Bad Salzuflen A2"
      },
      {
        "city": "Salzbergen",
        "coods": "52.326207, 7.429505",
        "place": "Aral Autohof Salzbergen 48499 Salzbergen"
      },
      {
        "city": "Klipphausen",
        "coods": "51.0628955020394, 13.5715113427515",
        "place": "Pension am Rasthof Dresdner Tor"
      },
      {
        "city": "Melle",
        "coods": "52.197626, 8.385262",
        "place": "Parking Gronegau Nord - 49328 Melle, Niemcy"
      },
      {
        "city": "Schwabhausen",
        "coods": "50.896583, 10.727111",
        "place": "An Der, B247, 99869 Schwabhausen, Niemcy"
      },
      {
        "city": "Könnern",
        "coods": "51.690196, 11.767468",
        "place": "Nordspange 1, 06420 Könnern"
      },
      {
        "city": "Grimma",
        "coods": "51.2615851988187, 12.7072898783014",
        "place": "Shell A14 Muldental-Nord"
      },
      {
        "city": "Möckern",
        "coods": "52.236375, 12.060994",
        "place": "Gewerbestraße 2"
      },
      {
        "city": "Ziesar",
        "coods": "52.25125, 12.3063784",
        "place": "14793, Unnamed Road, Ziesar, Niemcy"
      },
      {
        "city": "Nempitz",
        "coods": "51.2903769, 12.1380687",
        "place": "An der Autobahn 1, 06231 Nempitz, Niemcy"
      },
      {
        "city": "Spreenhagen",
        "coods": "52.311942, 13.9492571",
        "place": "Rastplatz Briesenluch Nord, Markgrafpieske 15528 Spreenhagen"
      },
      {
        "city": "Mittenwalde",
        "coods": "52.3172954, 13.4959451",
        "place": "15749, Unnamed Road, 15749 Mittenwalde, Niemcy"
      },
      {
        "city": "Möckern",
        "coods": "52.2443968, 12.144026",
        "place": "Rastplatz an der A2, Fahrtrichtung Berlin, 39291 Möckern, Niemcy"
      },
      {
        "city": "Königslutter am Elm",
        "coods": "52.3050439, 10.8425545",
        "place": "Parking place in Germany, 38154, Königslutter am Elm, Germany"
      },
      {
        "city": "Grevenbroich",
        "coods": "51.1294697, 6.6016065",
        "place": "Grevenbroich, Parking in Germany"
      },
      {
        "city": "Michendorf",
        "coods": "52.3029597, 13.0180643",
        "place": "Parking on the highway A10, Michendorf"
      },
      {
        "city": "Helmstedt",
        "coods": "52.220684, 11.05585",
        "place": "Parking Area: Rasthaus Lappwald, A2, 38350 Helmstedt"
      },
      {
        "city": "Hohe Börde",
        "coods": "52.165443, 11.50637",
        "place": "Parkplatz, 39326 Hohe Börde, Germany"
      },
    ],
  },
  {
    country: 'Italy',
    parkings: [
      {
        "city": "Tortoreto",
        "coods": "42.8059463, 13.927217",
        "place": "A14 - Autostrada Adriatica, 64018 Alba Adriatica TE, Italy"
      },
      {
        "city": "Katania",
        "coods": "37.4294, 15.0603",
        "place": "Contrada Pantano dArci, Katania, Italy"
      },
    ],
  },
  {
    country: 'Netherlands',
    parkings: [
      {
        "city": "Breda",
        "coods": "51.498758, 4.740252",
        "place": "Hazeldonk, 4836 LZ Breda, Netherlands"
      },
      {
        "city": "De Lutte",
        "coods": "52.31522, 7.0430533",
        "place": "48455 De Lutte, Netherlands"
      },
      {
        "city": "JZ Ochten",
        "coods": "51.9236958, 5.6028466",
        "place": "gas station Shell on the motorway A15 - Netherlands"
      },
      {
        "city": "Klarenbeek",
        "coods": "52.168302, 6.02447",
        "place": "Unnamed Road, Klarenbeek, Netherlands"
      },
      {
        "city": "Holten",
        "coods": "52.254838, 6.432924",
        "place": "Struik, Holten, Netherlands"
      },
      {
        "city": "Klarenbeek",
        "coods": "52.1685, 6.024833",
        "place": "The parking in near of Apeldoorn in Netherlands"
      },
      {
        "city": "Venlo",
        "coods": "51.38616, 6.21294",
        "place": "Venlo, Venlose Heide"
      },
    ],
  },
  {
    country: 'Poland',
    parkings: [
      {
        "city": "Zgorzelec",
        "coods": "51.156743, 15.028263",
        "place": "59-903 Zgorzelec, Poland, Parking TIR"
      },
      {
        "city": "Białków Górny",
        "coods": "52.150889, 18.567137",
        "place": "DW470, 62-604 Białków Górny, Poland"
      },
      {
        "city": "Mława",
        "coods": "53.1197268, 20.3943508",
        "place": "Polna 39, 06-500 Mława, Poland \"Lotos\""
      },
      {
        "city": "RYPIN",
        "coods": "53.082667, 19.413177",
        "place": "Gas station \"LOTOS\", Poland.Starorypin Prywatny 49, 87-500"
      },
      {
        "city": "Ogrodzona",
        "coods": "49.765556, 18.70175",
        "place": "Południowa 2, 43-426 Ogrodzona"
      },
      {
        "city": "Janów",
        "coods": "52.2717852, 20.1675714",
        "place": "Orlen Gas Station DK50, 96-512 Janów, Poland"
      },
      {
        "city": "Pomorzany",
        "coods": "52.253713, 19.200332",
        "place": "LUKOIL Pomorzany, DK92, 99-340, Poland"
      },
      {
        "city": "Nowostawy Dolne",
        "coods": "51.8996375, 19.7055434",
        "place": "MOP Nowostawy, 95-061, Poland, Shell"
      },
      {
        "city": "Kościelec",
        "coods": "52.1753243, 18.5768342",
        "place": "ORLEN - Petrol Station, Kolska 4, 62-604 Kościelec, Poland"
      },
      {
        "city": "Morawin",
        "coods": "51.8488464, 18.2599763",
        "place": "ORLEN - Petrol Station, Morawin 64, 62-834 Morawin, Poland"
      },
      {
        "city": "Gostynin",
        "coods": "52.41428, 19.471617",
        "place": "Parking Area"
      },
      {
        "city": "Żarska Wieś",
        "coods": "51.18987, 15.10715",
        "place": "MOP Żarska Wieś, 59-900 Żarska Wieś"
      },
      {
        "city": "Koziegłowy",
        "coods": "50.6221497, 19.1450225",
        "place": "Parking TIR in Koziegłowy (Poland)"
      },
      {
        "city": "Krze Duże",
        "coods": "52.003694, 20.590278",
        "place": "ul. Krze Duże 22A, 96-325 Krze Duże, Polska"
      },
      {
        "city": "Wolbórz",
        "coods": "51.502722, 19.870528",
        "place": "Parking TIR Gadki 10, 97-320 Wolbórz"
      },
      {
        "city": "Sięganów",
        "coods": "51.552444, 19.08775",
        "place": "Sięganów Południe"
      },
      {
        "city": "Cieszyn",
        "coods": "49.76631, 18.653212",
        "place": "Graniczna, 43-400 Cieszyn, Polska"
      },
    ],
  },
  {
    country: 'Slovakia',
    parkings: [
      {
        "city": "Hrádok",
        "coods": "48.707092, 17.871623",
        "place": "Parking area: 91633 Hrádok, Słowacja"
      },
    ],
  },
  {
    country: 'Slovakia',
    parkings: [
      {
        "city": "Madrid",
        "coods": "40.634686, -3.592799",
        "place": "A.S. Cepsa \"Las Pueblas Ctra. de Burgos Madrid, Hiszpania"
      },
      {
        "city": "Madrid",
        "coods": "41.040151, -3.618119",
        "place": "Diseminado, Madrid - Burgos, 81, 28737 La Serna del Monte, Madrid, Hiszpania"
      },
      {
        "city": "Guadalajara",
        "coods": "40.898037, -2.760015",
        "place": "A-2, 102, 19490 Almadrones, Guadalajara, Hiszpania"
      },
      {
        "city": "Torquemada",
        "coods": "42.050697, -4.252443",
        "place": "Parking Area: Diseminado Diseminados, 12734230 Palencia"
      },
      {
        "city": "Girona",
        "coods": "42.3995152, 2.8788987",
        "place": "Ctra. N2 PK772,5, 17700, Girona, Hiszpania"
      },
      {
        "city": "Guadalajara",
        "coods": "41.040334, 2.450536",
        "place": "A-2, 19260 Alcolea del Pinar, Guadalajara, Hiszpania"
      },
      {
        "city": "Guadalajara",
        "coods": "40.5484915, -3.2699859",
        "place": "19200 Azuqueca de Henares, Guadalajara, Hiszpania"
      },
    ],
  },
  {
    country: 'Sweden',
    parkings: [
      {
        "city": "Ystad",
        "coods": "55.42583, 13.833812",
        "place": "On the parking in Ystad (Sweden)"
      },
      {
        "city": "Ödeshög",
        "coods": "58.226278, 14.66925",
        "place": "599 31 Ödeshög, Sweden, Station OKQ8"
      },
    ],
  },
  {
    country: 'United Kingdom',
    parkings: [
      {
        "city": "Derby",
        "coods": "51.6886321, -0.2256568",
        "place": "Wilders Way, Derby DE74 2BB, Wielka Brytania"
      },
      {
        "city": "Leicester",
        "coods": "52.6184697, -1.2081274",
        "place": "Leicester Forest East, Leicester LE3 3GB, Wielka Brytania"
      }
    ],
  },
]
