import React from 'react'
import Layout from '../components/Layout'
import DangerousArea from '../page/DangerousArea/DangerousArea'

const DangerousAreaPage = () => (
  <Layout>
    <DangerousArea />
  </Layout>
)

export default DangerousAreaPage
